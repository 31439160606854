import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CustomDivider from '../components/customDivider';
import SmallContent from '../components/smallContent';

const TimeMachine = () => {
  const theme = useTheme();

  const timelineItems = [
    { text: "Under Construction..  ", fromRight: true },
    { text: "Behold! A digital time machine showcasing my creative works :)", fromRight: true },
    { text: "Here ill take you through my journey as a developer and game creator", fromRight: true },
    { text: "It all started as a child perhaps a mere 5 years old..", fromRight: true },
    { text: "My brothers and I got Pokemon Blue on the GameBoy and I was amazed..", fromRight: false },
    { text: "To be continued..", fromRight: false },
  ];

  return (
    <Box sx={{ width: '100%', my: 2 }}>
      {timelineItems.map((item, index) => (
        <React.Fragment key={index}>
          <SmallContent
            variant="h3"
            verticalSpacing={15}
            text={item.text}
            fromRight={item.fromRight}
          />
          {index !== timelineItems.length - 1 && (
            <CustomDivider color={theme.palette.secondary.main} />
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default TimeMachine;