import './App.css';
import '../themes/lightTheme.js';

import ActionAreaCard from '../card/card';
import cardDataList from "../cardDataList";
import {Draggable} from 'drag-react';

import * as React from 'react';
import { BrowserRouter as Router, Route, Routes, BrowserRouter, ScrollRestoration } from 'react-router-dom';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Link from '@mui/material/Link';
import { NavLink } from 'react-router-dom';

import { createTheme, ThemeProvider, Paper, Grid, Switch, Stack, styled} from "@mui/material";
import Divider from '@mui/material/Divider';
import lightTheme from '../themes/lightTheme.js';
import darkTheme from '../themes/lightTheme.js';
import { useMediaQuery } from '@mui/material';
import {useState, useEffect, View} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import About from '../components/about';
import Landing from '../components/landing';
import SmallContent from '../components/smallContent';
import CustomDivider from '../components/customDivider.js';
import Projects from './projects.js';
import AppRoutes from '../components/appRoutes.js';
import TimeMachine from './timeMachine.js';
import Blog from './blog.js';
import NavGrid from '../components/navGrid.js';

function App() {
  const isMobile = useMediaQuery(lightTheme.breakpoints.down('sm'));
  const isHalf = useMediaQuery(lightTheme.breakpoints.down('lg'));

  return (
    <ThemeProvider theme={lightTheme} >
      <Box 
        sx={{ 
          width: '100%', 
          backgroundImage: `url(${process.env.PUBLIC_URL}/bgPatterns/pattern_2.webp)`,
          backgroundRepeat: 'repeat',
        }}
      >
        {/*todo: add cute handwritten text next to grid pointing " start here!" with Vara library*/}
        <NavGrid dividerColor={lightTheme.palette.secondary.main}/>                                

        <Routes>
          <Route path ="/" element={<Landing />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path ="/timeMachine" element={<TimeMachine />} />
          <Route path="/blog" element={<Blog />} />
        </Routes>
              
              <CustomDivider startWidth={10} endWidth={90} color={lightTheme.palette.secondary.main} />

              <SmallContent id="collaborate" variant="h4" align="center" verticalSpacing={10} text="Want to collaborate? Don't hesitate and shoot me an electronic mail! pepndev@gmail.com" useAnimation={false} />
          
              <SmallContent id="footer" variant="h6" align="center" verticalSpacing={3} text="Copyright 2024©" useAnimation={false} />
    
        <Box sx={{ height: '10px' }} />
    </Box>
    </ThemeProvider>
  );
}
export default App;
