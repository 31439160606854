import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, Divider, useMediaQuery, useTheme } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';

const NavGrid = ({ isMobile, dividerColor }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const location = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setIsExpanded(location.pathname === '/');
  }, [location]);

  const navItems = [
    { to: "/projects", label: "Projects" },
    { to: "/timeMachine", label: "TimeMachine" },
    { to: "/about", label: "About" },
    { to: "/blog", label: "Blog" }
  ];

  return (
    <Container
      maxWidth={false}
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 1000,
        backgroundImage: `url(${process.env.PUBLIC_URL}/bgPatterns/pattern_2.webp)`,
        backgroundRepeat: 'repeat',
        width: '100%',
        maxWidth: '100%',
      }}
    >
      <Container 
        maxWidth={false} 
        sx={{
          textAlign: 'center',
          paddingTop: isExpanded ? '35vh' : 0,
          minHeight: isExpanded ? '100vh' : '0vh',
          transition: 'all 0.5s ease-in-out',
        }}
      >
        <Typography 
          variant="h1" 
          sx={{
            fontSize: isExpanded ? (isSmallScreen ? '48px' : '72px') : '0px',
            marginBottom: isExpanded ? 7 : 0,
            color: 'primary.main',
            transition: 'all 0.5s ease-in-out',
          }}
        >
          (GameSome.nl)
        </Typography>
        
        <Box sx={{
          display: 'grid',
          gridTemplateColumns: isExpanded ? '1fr' : 'repeat(4, 1fr)',
          width: '100%',
          maxWidth: isExpanded ? '600px' : '100%',
          margin: '0 auto',
          transition: 'all 0.5s ease-in-out',
        }}>
          {navItems.map((item) => (
            <NavLink
              key={item.to} 
              to={item.to}
              onClick={() => setIsExpanded(false)}
              style={({ isActive }) => ({
                textDecoration: isActive ? 'underline' : 'none',
                textDecorationColor: dividerColor,
              })}
            >
              <Typography 
                variant="h2" 
                sx={{
                  fontSize: isExpanded ? (isSmallScreen ? '24px' : '30px') : (isSmallScreen ? '18px' : '24px'),
                  padding: 1,
                  color: 'secondary.main',
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                  ...(!isExpanded ? {} : {
                    animation: 'moveLeftRight 4s ease-in-out infinite',
                    '@keyframes moveLeftRight': {
                      '0%, 100%': { transform: 'translateX(0)' },
                      '50%': { transform: 'translateX(3px)' },
                    },
                  }),
                }}
              >
                {item.label}
              </Typography>
            </NavLink>
          ))}
        </Box>
      </Container>
      

      {isMobile ? null : (
      <Divider 
          textAlign="left" 
          sx={{
            fontSize: isMobile ? '13px' : '17px', 
            m: 0, 
            '&.MuiDivider-root': { 
              borderTop: `3px solid ${dividerColor}`
            }
          }} 
        />
      )}
      
    </Container>
  );
};

export default NavGrid;
