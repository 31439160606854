import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, CardActionArea, Slide } from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
import { useInView } from 'react-intersection-observer';
import { StyledCard } from './styles.js';
import lightTheme from '../themes/lightTheme.js';
import useMediaQuery from '@mui/material/useMediaQuery';

export const CardTextTitle = ({ children }) => (
  <Typography variant="h4" component="div" color="text.primary" mt={2} pt={2} mb={0.5}>
    {children}
  </Typography>
);

export const CardTextParagraph = ({ children }) => (
  <Typography variant="body1" color="text.secondary" fontSize={16} mb={2} ml={0.2}>
    {children}
  </Typography>
);

export default function CardText({ title, children }) {
  const isMobile = useMediaQuery(lightTheme.breakpoints.down('sm'));
  const [roll, setRoll] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setRoll(true);
    }, 1000);
  }, []);

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <Box sx={{ mt: 5, mx: 2 }}>
      <TransitionGroup>
        <Slide direction="left">
          <StyledCard entered={inView ? "true" : "undefined"} theme={lightTheme} sx={{ 
            backgroundColor: "#fffcf588",
            height: 300,
          }}>
            <CardActionArea sx={{ height: '100%' }}>
              <CardContent sx={{
                mx: 2, 
                mt: 1, 
                p: 0, 
                pl: 1, 
                height: "90%",
                borderLeft: `4px dotted ${lightTheme.palette.secondary.main}`
              }}>
                {children}
              </CardContent>
            </CardActionArea>
          </StyledCard>
        </Slide>
      </TransitionGroup>
    </Box>
  );
}
