import * as React from 'react';
import Card from '@mui/material/Card';
import styled from "@mui/material/styles/styled";
import {keyframes} from '@mui/material';

const textEffect = keyframes`
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }`;

const rotateIn = keyframes`
    0% {
      -webkit-transform: translateZ(200px) rotate(10deg);
              transform: translateZ(200px) rotate(10deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0) rotate(0);
              transform: translateZ(0) rotate(0);
      opacity: 1;
    }`;

const StyledCard = styled(Card)(({entered, theme}) => ({
    background: '#0c0cfcc',
    //borderStyle : "dashed" | "dotted" | "double" | "groove" | "hidden" | "inset" | "none" | "outset" | "ridge" | "solid";
    borderTop: `4px solid ${theme.palette.secondary.main}`,
    borderLeft: `4px solid ${theme.palette.secondary.main}`,
    boxShadow: 'none',
    borderRadius: '0%',
    color: '#000000',
    '&:hover': {
        color: '#000000'
    },
    animation: `${rotateIn}  0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both`,
    animation: entered && `${rotateIn} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`,
    visibility: !entered && 'hidden',
    height: 500,
  }));

const ProgrammingChipsLI = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

export {StyledCard,ProgrammingChipsLI};