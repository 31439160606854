import * as React from 'react';
import Chip from '@mui/material/Chip';
import styled from "@mui/material/styles/styled";
import { red,grey, green,blue,yellow, indigo} from '@mui/material/colors';

function colorForLanguage(status) {
    switch (status) {
        case "c++":
            return yellow;
        case "C#":
            return green;
        case "Unity":
            return blue;
        case "Python":
            return red;
        case "Javascript":
            return indigo;
        case "React":
              return indigo;
        default:
            return grey;
    }
  }

function ProgrammingChip({ value }) {
    return (
      <Chip 
        variant="outlined"
        label={value}
        size="small"
        style={{
            //padding: "0px 0px", 
            //fontSize: 20, 
            
            //height: 20,
            borderRadius: '0%',
            color: colorForLanguage(value)[700], 
            borderColor:colorForLanguage(value)[700]
        }}
      />
    );
  }

export {ProgrammingChip};