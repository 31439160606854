import './App.css';
import '../themes/lightTheme.js';

import ActionAreaCard from '../card/card';
import cardDataList from "../cardDataList";

import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import Divider from '@mui/material/Divider';
import lightTheme from '../themes/lightTheme.js';
import { useMediaQuery } from '@mui/material';
import {useState, useEffect, View} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Container from '@mui/material/Container';
import SmallContent from '../components/smallContent';

function Projects() {
  const isMobile = useMediaQuery(lightTheme.breakpoints.down('sm'));
  const isHalf = useMediaQuery(lightTheme.breakpoints.down('lg'));
  const [cardData, setCardData] = useState([]);
  const [sortType, setSortType] = useState('relevance');
  const [filterType, setFilterType] = useState('all');

  useEffect(() => {
    const sortAndFilterArray = () => {
      let sorted = [...cardDataList];

      // Sort
      const types = {
        relevance: 'relevance',
        date: 'endDate',
      };
      const sortProperty = types[sortType];
      sorted.sort((a, b) => b[sortProperty] - a[sortProperty]);

      // Filter
      if (filterType !== 'all') {
        sorted = sorted.filter(item => item.tags && item.tags.includes(filterType));
      }

      setCardData(sorted);
    };

    sortAndFilterArray();
  }, [sortType, filterType]);

  return (
    <Box>
        <SmallContent
            variant="h3"
            verticalSpacing={20}
            text={"Here you find some of the projects I've worked on, in a team, or solo."}
            fromRight={true}
            useAnimation={false}
        />                                            
        {isMobile ? null : (<Divider textAlign="left" flexItem sx={{fontSize:isMobile ? '13px' : '17px', m: 0, backgroundColor: '#00000000', '&.MuiDivider-root': { '&::before': { borderTop: `4px solid ${lightTheme.palette.secondary.main}`, }, 
                                                                                                            '&::after': { borderTop: `4px solid ${lightTheme.palette.secondary.main}`, }}}} >
            sort projects by:&nbsp;
            <FormControl flexItem sx={{ m: 0, p:0, height:20 }} size="small">
            <InputLabel id="demo-simple-select-label" >Sort</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={sortType}
              label="sort"
              sx={{ m: 0, p:0, height:20 }}
              onChange={(e) => setSortType(e.target.value)}
            >
              <MenuItem size="small" value="relevance">Relevance</MenuItem>
              <MenuItem size="small" value="date">Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</MenuItem>
            </Select>
          </FormControl>

          &nbsp;&nbsp;| filter projects by:&nbsp;
            <FormControl flexItem sx={{ m: 0, p:0, height:20 }} size="small">
            <InputLabel id="demo-simple-select-label2" >Filter</InputLabel>
            <Select
              labelId="demo-simple-select-label2"
              id="demo-simple-select2"
              value={filterType}
              label="filter"
              sx={{ m: 0, p:0, height:20 }}
              onChange={(e) => setFilterType(e.target.value)}
            >
              <MenuItem size="small" value="all">All&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</MenuItem>
              <MenuItem size="small" value="work">Work&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</MenuItem>
              <MenuItem size="small" value="personal">Personal</MenuItem>
            </Select>
          </FormControl>
        </Divider>)}

        <Container maxWidth={false}>
          <ImageList cols={isMobile ? 1 : (isHalf ? 2 : 3)}  gap={20} sx={{alignItems:'center', overflowY: "visible", p:0}}>
            {cardData.map((item) => {
              return(
              <ImageListItem key={item.source} sx={{m:0, pt:1, pb:0}}>
                <ActionAreaCard 
                                        name={item.title}
                                        width={item.width}
                                        height={item.height}
                                        title={item.title}
                                        smallTextContent={item.smallTextContent}
                                        largeTextContent={item.largeTextContent}
                                        dateFrom={item.dateFrom}
                                        dateEnd={item.dateEnd}
                                        source={item.source}
                                        programmingChips={item.programmingChips}
                                        theme={lightTheme}
                                         />
              </ImageListItem>)})}
          </ImageList>
        </Container>
        <Box sx={{ height: '10px' }} />
    </Box>
  );
}
export default Projects;
