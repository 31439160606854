import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Dialog, keyframes, Paper } from '@mui/material';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useInView, InView } from 'react-intersection-observer';
import {TransitionGroup} from 'react-transition-group';
import Slide from "@mui/material/Slide";

import {StyledCard,ProgrammingChipsLI} from './styles.js';
import {ProgrammingChip} from '../progchip/ProgrammingChip.js';
import { ThemeContext } from '@emotion/react';
import lightTheme from '../themes/lightTheme.js';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function ActionAreaCard(props) {

  const isMobile = useMediaQuery(props.theme.breakpoints.down('sm'));
  const [roll, setRoll] = React.useState(false);
  const [entered, SetVisibility] = React.useState(false);
  React.useEffect(()=>{
    setTimeout(()=>{
      setRoll(true);
    }, 1000);
  });

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  });


    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');
  
     const handleClickOpen = (scrollType) => () => {
      setOpen(true);
      setScroll(scrollType);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
      if (open) {
        const { current: descriptionElement } = descriptionElementRef;
        if (descriptionElement !== null) {
          descriptionElement.focus();
        }
      }
    }, [open]);

    return (
        <div ref={ref}>
        <TransitionGroup>
            <Slide direction ="left">
            <StyledCard entered={inView == true ? "true" : undefined} theme={lightTheme} sx={{ 
              backgroundColor:"#fffcf588"
            }}>
            <CardActionArea onClick={handleClickOpen('paper')} sx={{height:'100%'}}>
                <CardMedia
                  component="img"
                  height="50%"
                  image={isMobile ? props.source.replace('/img/', '/img/mobile/') : props.source}
                  alt="pls load img.."
                  sx={{mt:5, mx:2, width:'90%', borderStyle: 'fill',     borderTop: `4px solid ${lightTheme.palette.secondary.main}`,
                  borderLeft: `4px solid ${lightTheme.palette.secondary.main}`}}
                  loading = "lazy"
                />
                <CardContent sx={{mx:2, mt:1, p:0, pl:1, height:"35%",
                  borderLeft: `4px dotted ${lightTheme.palette.secondary.main}`}}>
                  <Typography variant="h5" component="div" color="text.primary" textAlign="left" >
                      {props.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" minHeight={60}>
                      {props.smallTextContent}
                  </Typography>
                    <Paper sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                        p: 0,
                        pt: 1,
                        m: 0,
                        backgroundColor: '#ff00ff00',
                      }} 
                    component='ul'
                    elevation={0}>
                      {props.programmingChips.map((data) =>{
                        return (<ProgrammingChipsLI key={data} ><ProgrammingChip key={data} value={data}></ProgrammingChip></ProgrammingChipsLI>);
                      })}
                    </Paper>
                </CardContent>
                
            </CardActionArea>
            </StyledCard>
            </Slide>
            </TransitionGroup>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="DialogComplete"
                fullWidth={true}
                maxWidth="md"
                borderRadius='100%'
                sx={{
                  ...(isMobile
                    ? {
                        "& .MuiDialog-container": {
                          "& .MuiPaper-root": {
                            minWidth: "95%",
                          },
                        },
                      }
                    : {}),
                }}
                >
                <DialogTitle id="scroll-dialog-title">[{props.title}] ({props.dateFrom}-{props.dateEnd})</DialogTitle>
                <DialogContent dividers={scroll === 'paper'} sx={{mx:3, borderLeft: `2px solid ${lightTheme.palette.secondary.main}`, borderTop: `2px solid ${lightTheme.palette.secondary.main}`, borderBottom: "none"}} >
                <DialogContentText
                    id="DialogContentText"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                    component="div"
                >
                    {props.largeTextContent()}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button variant="outlined" size="small" onClick={handleClose}>Close</Button>
                </DialogActions>
              </Dialog>
    </div>
  );
}