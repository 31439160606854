import React, { useRef, useEffect, useState } from 'react';
import { Typography, Container, Box } from '@mui/material';
import { keyframes } from '@mui/system';

const swipeInFromRight = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const swipeInFromLeft = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

function SmallContent({ 
  id = "undefined",
  verticalSpacing = 16, 
  text = "Some content line goes here buddy!",
  variant = "h3",
  duration = 1,
  fromRight = true,
  useAnimation = true // New prop to determine if animation should be used
}) {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      {
        threshold: 0.03 // Trigger when 10% of the element is visible
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  const animationKeyframes = useAnimation ? (fromRight ? swipeInFromRight : swipeInFromLeft) : null;
  const initialTransform = useAnimation ? (fromRight ? 'translateX(100%)' : 'translateX(-100%)') : 'translateX(0)';

  return (
    <Container maxWidth="md" id={id} sx={{ overflow: 'hidden' }}>
      <Box sx={{ my: verticalSpacing, textAlign: 'center' }} ref={ref}>
        <Typography 
          variant={variant} 
          gutterBottom 
          align="center"
          sx={{
            animation: useAnimation && isVisible ? `${animationKeyframes} ${duration}s ease-out` : 'none',
            opacity: useAnimation ? (isVisible ? 1 : 0) : 1,
            transform: useAnimation ? (isVisible ? 'translateX(0)' : initialTransform) : 'none',
            transition: useAnimation ? `opacity ${duration}s, transform ${duration}s` : 'none',
          }}
        >
          {text}
        </Typography>
      </Box>
    </Container>
  );
}

export default SmallContent;
