import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CustomDivider from '../components/customDivider';
import SmallContent from '../components/smallContent';

const Blog = () => {
  const theme = useTheme();

  return (
    <Box sx={{ width: '100%', my: 0, textAlign: 'center' }}>
      <SmallContent
        variant="h2"
        verticalSpacing={25}
        text="Blog"
      />
      <CustomDivider color={theme.palette.secondary.main} />
      <Box sx={{ my: 4 }}>
        <SmallContent
          variant="h4"
          verticalSpacing={50}
          text="There are no blogs yet; I told you we're under construction ;)"
        />
        <SmallContent
          variant="h4"
          verticalSpacing={120}
          text="Still scrolling..?"
        />
        <SmallContent
          variant="h4"
          verticalSpacing={80}
          text="Alright then, here is a nice story."
        />
        <SmallContent
          variant="h4"
          verticalSpacing={0}
          text="Max, a game dev, built a magical kingdom for players to explore. His game became a sensation, bringing joy to many."
        />
        <SmallContent
          variant="h4"
          verticalSpacing={15}
          text="Thx AI."
        />
      </Box>
    </Box>
  );
};

export default Blog;