import React from 'react';
import { Box, Divider } from '@mui/material';

function CustomDivider({ startWidth = 5, endWidth = 95, color, ...props }) {
  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      <Divider
        sx={{
          position: 'absolute',
          left: `${startWidth}%`,
          right: `${100 - endWidth}%`,
          borderTop: `3px solid ${color}`,
        }}
        {...props}
      />
    </Box>
  );
}

export default CustomDivider;