import React from 'react';
import { Typography, Container, Box, Grid, Link } from '@mui/material';

function Landing() {
  const verticalSpacing = 16;

  return (
    <Container maxWidth="md" id="about">
      <Box sx={{ my: verticalSpacing }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h2" gutterBottom>
              (Hello!)
            </Typography>
            <Typography variant="h4" paragraph>
              I'm a passionate dutch game developer, conjuring my own creations or as a{' '}
              <Link href="#collaborate" underline="always" color="inherit">
                freelancer
              </Link>
              .
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={`${process.env.PUBLIC_URL}/img/aboutme.png`}
              alt="Background pattern"
              sx={{
                width: '100%',
                height: 'auto',
                maxWidth: '400px',
                display: 'block',
                margin: '0 auto',
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default Landing;