import { createTheme, ThemeProvider } from "@mui/material";

const lightTheme = createTheme({
    palette: {
      type: 'light',
      primary: {
        main: '#000000',
      },
      secondary: {
        main: '#3c3c3c',
      },
      background: {
        paper: '#fffcf5;',
      },
      divider: '#000000',
      info: {
        main: '#9cefc4',
      },
      text: {
        primary: '#000000',
        secondary: '#3c3c3c',
      },
      shadedPrimary: {
        main: "#E0F7FF",
      },
    },
    typography: {
      fontFamily: [
        "fixedsys",
        "Roboto",
        "Helvetica Neue",
        "Arial",
        "sans-serif"
      ].join(",")
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 720,
        md: 1080,
        lg: 1300,
        xl: 1920,
      }}
  });

//8FB89D #87FFAF BD4C4A FFACAB
  export default lightTheme;