import React from 'react';
import { Routes, Route } from 'react-router-dom';
import App from '../App/App';
import About from './about';
import Projects from '../App/projects';

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/about" element={<About />} />
      <Route path="/projects" element={<Projects />} />
    </Routes>
  );
}

export default AppRoutes;
